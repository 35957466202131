<template>
    <modal-lateral ref="modalChatConsulta" titulo="Habla con un asesor">
        <ValidationObserver ref="form">
            <div class="overflow-auto custom-scroll pt-3" style="height:calc(100vh - 153px);background-color:#F6F9FB;">
                <div v-for="(data, index) in mensajes" :key="index" class="row mx-0 mb-3" :class="false ? 'justify-content-start' : 'justify-content-end'">
                    <div class="p-2 f-14" :class="data.created_by != $usuario.id ? 'chat-bubble-left' : 'chat-bubble-right'">
                        <p v-if="data.tipo == 1" class="text-black space-text">
                            {{ data.mensaje }}
                        </p>
                        <div v-if="data.tipo == 2" class="row mx-0 j-center">
                            <img :src="data.mensaje" style="max-width:240px;height:134px;" />
                        </div>
                        <div v-if="data.tipo == 3" class="card-file cr-pointer" @click="openDocument">
                            <div class="space-file">
                                <i class="icon-image f-18" />
                            </div>
                            <div class="col f-12">
                                {{ identificarNombre(data.mensaje) }}
                            </div>
                        </div>
                        <p class="f-12 text-gris2 mt-2">
                            {{ data.created_at | helper-fecha('h:mm a') }}
                        </p>
                        <!-- Arrows -->
                        <div :class="data.created_by != $usuario.id ? 'chat-bubble-arrow-left' : 'chat-bubble-arrow-right'" />
                    </div>
                </div>
            </div>
            <ValidationProvider rules="required">
                <div v-if="type != 'finished'" class="bg-white space-input-text d-middle py-2">
                    <el-upload
                    ref="adjuntos"
                    class="upload-demo f-18"
                    action="#"
                    :multiple="false"
                    :limit="1"
                    :on-change="handlePreview"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    :show-file-list="false"
                    >
                        <i class="icon-image cr-pointer" />
                    </el-upload>
                    <div class="col mr-2 d-middle input-chat position-relative">
                        <el-input v-model="model.mensaje" type="textarea" class="w-100 br-20" placeholder="Comentar" :rows="3" @keyup.enter.native="sendMessage" />
                    
                        <div class="cr-pointer" @click="sendMessage">
                            <i class="icon-send-message text-black f-20" />
                        </div>
                    </div>
                </div>
                <div v-else class="bg-white space-input-text d-middle py-2">
                    <div class="col f-600 f-20 text-center">
                        <p>Chat finalizado</p>
                    </div>
                </div>
            </ValidationProvider>
        </ValidationObserver>
    </modal-lateral>
</template>

<script>
import Service from '@/services/ayuda/ayuda'
export default {
    data(){
        return{
            mensajes: [],
            model: {
                idPedido: null,
                tipoMensaje: 1,
                mensaje: null,
                file: null,
                estado: null,
            },
            type: null,
        }
    },
    methods: {
        toggle(type){
            this.type = type;
            this.getData(type);
            this.$refs.modalChatConsulta.toggle();
        },

        async getData(type){
            try{
                const params = {
                    type: type
                };
                const {data} = await Service.getChatConsultas(params);
                this.mensajes = data.mensajes;
            }catch(e){
                this.errorCatch(e)
            }
        },

        async sendMessage(){
            try {
                if(!this.model.mensaje && !this.model.file)return;
                if(this.type == 'finished')return;
                
                let model = {};
                if(parseInt(this.model.tipoMensaje) === 2 || parseInt(this.model.tipoMensaje) === 3){
                    model = this.crearFormData(this.model)
                } else {
                    this.model.tipoMensaje = 1;
                    model = this.model;
                }
                
                const {data} = await Service.postChatConsulta(model);
                this.mensajes = [...this.mensajes, data.newMessage];
                this.$emit('update',  this.mensajes.length);
                this.limpiar();
            } catch(e){
                this.errorCatch(e)
            }
        },

        identificarNombre(value){
            let data = value.split('/');
            if(data.length === 6){
                return data[5];
            }
            return 'archivo adjunto'
        },

        openDocument(archivo){
            window.open(archivo, 'download')
        },

        handlePreview(file){
            this.model.file = file.raw;

            let ext = file.raw.type.split("/");
            
            if(ext[0] == 'image' || ext[1] == 'image'){
                this.model.tipoMensaje = 2;
            } else {
                this.model.tipoMensaje = 3;
            }

            this.sendMessage();
        },

        handleRemove(){
            this.model.file = null;
            this.model.tipoMensaje = 1;
        },

        async limpiar(){
            this.model.mensaje = null;
            this.model.file = null;
            this.model.tipoMensaje = 1;
            this.$refs.adjuntos.clearFiles();
        },
    }
}
</script>
<style lang="scss" scoped>
.space-input-text{
    border-radius: 12px 12px 0px 0px;
    right: 0px;
    box-shadow: 0px 3px 6px #00000040;
}

.card-file{
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    .space-file{
        width: 48px;
        height: 100%;
        border-radius: 12px 0px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DFE4E8;
    }
    .space-delete{
        width: 60px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        background-color: #FF5158;
    }
}
</style>