import { render, staticRenderFns } from "./modalChatConsulta.vue?vue&type=template&id=cd773544&scoped=true&"
import script from "./modalChatConsulta.vue?vue&type=script&lang=js&"
export * from "./modalChatConsulta.vue?vue&type=script&lang=js&"
import style0 from "./modalChatConsulta.vue?vue&type=style&index=0&id=cd773544&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd773544",
  null
  
)

export default component.exports